export const javaWords = [
    'Microservices',
    'GRPC',
    'Akka',
    'Lucene',
    'JDBC',
    'Jini/RMI',
    'Apache-JMeter',
    'Maven',
    'Apache-Kafka',
    'Apache-Storm',
    'Apache-Hadoop',
    'Spring-Framework',
    // 'PayPal-SDK',
    'WebSockets/Atmosphere',
    'Hazelcast',
    // 'FreeMarker',
    'Jetty',
    // 'Epublib',
    // 'Parlay',
    'org.apache.avro',
    'J2SE',
    'J2ME',
    'J2EE',
    'Docker',
]

export const scalaWords = [
    'sbt',
    'akka.actor',
    'com.google.bigtable.v2',
    'com.google.cloud.storage',
    'com.google.protobuf',
    'com.google.pubsub',
    'org.apache.avro',
    'com.spotify.scio',
    'org.elasticsearch',
    'io.grpc',
    'gatling',
    'kafka',
    'org.apache.beam',
    'org.json4s',
    'play.api',
    'Docker',
];

export const pythonWords = [
    'jupyter',
    // 'argparse',
    // 'bluetooth',
    // 'collections',
    // 'csv',
    //'ctypes',
    'curses',
    //'datetime',
    'dbus.service',
    //'distutils',
    // 'django',
    'dns',
    'docker',
    'docutils',
    //'encodings',
    'fabric',
    //'flake8',
    'flask_restful',
    //'gdbrunner',
    // 'getopt',
    'googleapiclient',
    'google.api_core',
    'google.auth',
    'google.cloud.bigquery.dataset',
    'google.cloud.logging_v2',
    'google.oauth2.credentials',
    'google.protobuf',
    'graphviz',
    'grpc',
    'gzip',
    'html5lib',
    //'http.client',
    'imageio',
    //'jinja2',
    // 'json',
    // 'jsonschema',
    'kibana',
    'ldap',
    // 'locale',
    'luigi',
    'lxml.etree',
    'markdown',
    'matplotlib',
    //'mimetypes',
    //'mypy',
    'numpy',
    // 'optparse',
    'pandas',
    // 'pprint',
    // 'py3status',
    // 'pyaudio',
    'pycurl',
    're',
    //'regex',
    // 'requests',
    'rtmidi',
    'scipy',
    //'socket',
    'sqlite3',
    'srvlookup',
    'subprocess',
    'threading',
    //'tox',
    //'urllib',
    //'urlparse',
    'websocket',
    'xml.etree.ElementTree',
    // 'yaml',
    'zmq',
]

export const linuxWords = [
    'Ubuntu',
    'Slackware',
    'Debian',
    'Raspberry pi Debian',
    'Elasticsearch',
    'Vespa',
    'Kafka',
    'Zookeeper',
    'dpkg',
    'ldap',
    'dns',
    'Terraform',
    'Prometheus',
    'Docker',
]

export const k8sWords = [
    'GKE',
    'kubectl',
    'K9s',
    'operator development',
    'golang',
    'Terraform',
    'Prometheus',
    'Helm',
    'Kind',
    'Docker',
]

export const gcpWords = [
    'GKE',
    'Terraform',
    'Compute Engine',
    'Managed Instance Groups',
    'PubSub',
    'BigTable',
    'BigQuery',
    'GCS',
    'gcloud',
    'Docker',
]

function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}

export function getWordData(words) {
    const res = []
    for (var i in words) {
        const word = words[i];
        res.push({ text: word, value: getRandomInt(10000) });
    }
    return res;
}