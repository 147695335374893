import React from "react";
import { BrowserRouter, Outlet, Route, RouterProvider, Routes, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import './App.css';
import AuthProvider from "./AuthProvider";
import MyRoutes from './routes';
import { MainPage } from './MainPage';
import { TermsOfUse } from "./TermsOfUse";
import Login from "./Login";
import ApiTest from "./ApiTest";
import Logout from "./Logout";
import { Alert, Button, Col, Container, Row } from "react-bootstrap";

function LayoutComponent() {

  return (
    <div>
      {/* <header>Header Content</header> */}
      <main>
        <Outlet /> {/* Nested routes render here */}
      </main>
      {/* <footer>Footer Content</footer> */}
    </div>
  );

}

function NotFoundPage() {
  // return <h1>404 - Page Not Found</h1>;
  return (
    <div>
      <Container fluid>
        <Button href="/" variant="secondary">⋘ Back</Button>
      </Container>
      <Container>
        <Row>
          <Col>
            <Alert variant="danger">404 - Page Not Found</Alert>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/logout",
    element: <Logout />,
  },
  {
    path: "/api-test",
    element: <ApiTest />,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
  {
    path: '/',
    element: <LayoutComponent />,
    children: [
      {
        index: true,
        element: <MainPage />,
      },
      {
        path: 'tos',
        element: <TermsOfUse />,
      },
    ],
  },

]);


function App() {


  // return (
  //   <BrowserRouter>
  //     <Routes>
  //       <Route path="/" element={<LayoutComponent />}>
  //         <Route index element={<MainPage/>} />
  //         <Route path="tos" element={<TermsOfUse/>} />
  //         <Route path="*" element={<NotFoundPage />} />
  //       </Route>
  //     </Routes>
  //   </BrowserRouter>
  // )

  return (
    <AuthProvider>
      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>
    </AuthProvider>
  );

  // return (
  //   <AuthProvider>
  //     <MyRoutes />    
  //   </AuthProvider>
  // );
}

export default App;
