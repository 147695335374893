import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';

// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/css/bootstrap.css';
import './custom.scss';
import App from './App';


// function ParamPage() {

//   // Get the userId param from the URL.
//   const [queryParameters] = useSearchParams()

//   const page = queryParameters.get("page");

//   console.log("PAGE", page);
//   switch (page) {
//     case "terms":
//       return <TermsOfUse />
//     case "cv":
//       return <CV />
//     default:
//       return <MainPage />
//   }
// }

// const router = createBrowserRouter([
//   ...createRoutesFromElements(
//     <Route path="/" element={<ParamPage />} />
//   )
// ]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App></App>
  // <React.StrictMode>
  //   <App />
  //   {/* <RouterProvider router={router} /> */}
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
