import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthProvider";

const Logout = () => {
    const { setToken } = useAuth();
    const navigate = useNavigate();

    //   const handleLogout = () => {
    //     setToken();
    //     navigate("/", { replace: true });
    //   };

    //   setTimeout(() => {
    //     handleLogout();
    //   }, 10);

    setToken();
    localStorage.setItem('userid', null);
    localStorage.setItem('givenName', null);
    localStorage.setItem('familyName', null);
    localStorage.setItem('roles', null);
    localStorage.setItem('expiresAtMs', null);
    navigate("/", { replace: true });

    return <>Logout Page</>;
};

export default Logout;
