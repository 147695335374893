import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import { Alert, Button, Col, Container, Row } from "react-bootstrap";

const ApiTest = () => {
  // const { setToken } = useAuth();
  const [token] = useState(localStorage.getItem("token"));
  const navigate = useNavigate();
  // const {}
  const [error, setError] = useState(null);
  const [apiResponse, setApiResponse] = useState(null);

  useEffect(() => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    axios({
      method: 'get',
      url: '/api/secured/roles-allowed-admin',
      // headers: [
      //   "Authorization: Bearer " + token,
      // ],
    }).then((response) => {
      console.log("axios response", response);
      // navigate("/", { replace: true });
      setApiResponse("" + response.data);
    }, (error) => {
      console.log("axios error", error);
      setError("" + error.message)
    })
  });

  return (
    <div>
      <Container fluid>
        <Button href="/" variant="secondary">⋘ Back</Button>
      </Container>
      <Container>
        {apiResponse && (
          <Row>
            <Col>
              API-Response:
              {apiResponse && (
                <Alert key="success" variant="success">{apiResponse}</Alert>
              )}
            </Col>
          </Row>
        )}
        {error && (
          <Row>
            <Col xs={10}>
              ERROR:
              <Alert variant="danger">Error: {error}</Alert>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default ApiTest;
