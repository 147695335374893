import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { useAuth } from "../AuthProvider";
import { ProtectedRoute } from "../ProtectedRoute";
import Login from "../Login";
import Logout from "../Logout";
import { MainPage } from "../MainPage";
import { TermsOfUse } from "../TermsOfUse";

const MyRoutes = () => {

  const token = useAuth();

  // Define public routes accessible to all users
  const routesForPublic = [       
    // {
    //   path: "/tos",
    //   element: <TermsOfUse/>
    // },
    // {
    //   path: "/about-us",
    //   element: <div>About Us</div>,
    // },
    {
      path: "",
      // element: <MainPage/>,
      element: <div>main</div>,
      children: [
        {
          path: "tt",
          element: <div>TT</div>,
        },
      ],
    },
  ];

  // Define routes accessible only to authenticated users
  const routesForAuthenticatedOnly = [
    {
      path: "/",
      element: <ProtectedRoute />, // Wrap the component in ProtectedRoute
      children: [
        {
          path: "/secured",
          element: <div>Secured path</div>
        },
        {
          path: "/v1",
          element: <div>V1 API</div>,
        },
        {
          path: "/profile",
          element: <div>User Profile</div>,
        },
        {
          path: "/logout",
          element: <Logout/>,
        },
      ],
    },
  ];

  // Define routes accessible only to non-authenticated users
  const routesForNotAuthenticatedOnly = [   
    {
      path: "/login",
      element: <Login/>,
    },
  ];

  // Combine and conditionally include routes based on authentication status
  const router = createBrowserRouter([
    ...routesForPublic,
    ...(!token ? routesForNotAuthenticatedOnly : []),
    ...routesForAuthenticatedOnly,
  ]);
  console.log("router", router);

  // Provide the router configuration using RouterProvider
  return <RouterProvider router={router} />;
};

export default MyRoutes;
